import api from './baseApi';

const masterDataApi = {
  getDiv(divName) {
    return api.get(`/divs/${divName}/values`);
  },
  getCustomers() {
    return api.get(`/customer?take=1000`);
  },
  getBases(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/base?${childPath}`);
  },
  getRoutes() {
    return api.get(`/route`);
  },
  getAddresses(postCd) {
    return api.get(`/address_jis?zipcode=${postCd}`);
  },
  getCompanies() {
    return api.get('/companies?take=1000');
  },
  getDrivers() {
    return api.get('/drivers?take=1000');
  },
  getMServices(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/m_services?${childPath}`);
  },
  getCarType(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/car_type?${childPath}`);
  },
  getSlots(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/slots?${childPath}`);
  },
  getCustomersV2() {
    return api.get('/customer/get-sp-barcode-customers');
  },
};

export default masterDataApi;
